import React, { useEffect, useState } from "react";
import "../Styles/InputData.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Header,
  Footer,
  ContactDetails,
  PassengerInfo,
  Loader,
} from "../index";
import { bookTicketApi } from "../../api/ApiUtils";
import { formatCurrency } from "../../common/Utils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";
import { StationTicketCard } from "../CommonComponents/StationTicketCard/StationTicketCard";

const InputData = () => {
  const header = "Isi Data";
  const navigate = useNavigate();
  const [stationTiciketCard, setStationTiciketCard] = useState({});
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let KAI = JSON.parse(localStorage.getItem("KAI"));
    if (!KAI?.stationDetails || !KAI?.ticketDetails) navigate("/");
    else {
      const { stationDetails, ticketDetails } = KAI;
      let stationTiciketCard = {
        departdate: ticketDetails.departdate,

        originStation: stationDetails.sourceStation.stationname,
        destinationStation: stationDetails.destnationStation.stationname,

        departureTime: ticketDetails.departuretime,
        arrivalTime: ticketDetails.arrivaltime,

        trainName: ticketDetails.trainname,
        wagonClass: ticketDetails.wagonclasscode,
        subClass: ticketDetails.subclass,

        adultPassengers: stationDetails.adultPassengers,
        childPassengers: stationDetails.childPassengers,
        totalPassengers:
          parseInt(stationDetails.adultPassengers) +
          parseInt(stationDetails.childPassengers),
        totalAmount:
          parseInt(stationDetails.adultPassengers) *
          parseInt(ticketDetails.fares[0].amount),
        trainNumber: ticketDetails.noka,
        stasiunOrginCode: ticketDetails.stasiunorgcode,
        stasiunDestCode: ticketDetails.stasiundestcode,
      };
      setStationTiciketCard(stationTiciketCard);
    }
  }, [navigate]);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  const validatePassengerData = (passenger) => {
    const pasporError =
      passenger.passengerID && "Paspor" && passenger.numberID.length === 8;
    const ktpError =
      passenger.passengerID && "KTP" && passenger.numberID.length === 16;
    const childDateError =
      passenger.passenger &&
      "child" &&
      moment(stationTiciketCard.departdate, "YYYY-MM-DD").diff(
        moment(passenger.childBirthDate).subtract(1, "days"),
        "years"
      ) < 3;
    return (
      passenger.name &&
      (pasporError || ktpError) &&
      (passenger.passenger === "adult" || childDateError)
    );
  };

  const handlePesanSubmit = async () => {
    const KAI = JSON.parse(localStorage.getItem("KAI"));
    if (!(KAI.passengerList && KAI.customerContactDetails)) {
      setError(true);
      return;
    }

    let { customerContactDetails } = KAI;
    // check for customerContactDetails contains all the data.
    let { name, phoneCode, phoneNo, email } = customerContactDetails;
    if (!(name && phoneCode && phoneNo && email !== "")) {
      setError(true);
      return;
    }
    // min no.of passenger is 1 so there will be always atleast 1 passenger.
    // check for passengerList contains all the data.
    let passengerListCheck = true;
    let { passengerList, ticketDetails, stationDetails } = KAI;
    for (const item of passengerList) {
      passengerListCheck = validatePassengerData(item);
      // NEED TO BREAK LOOP HERE AND RETURN passengerListCheck ===> false
      if (!passengerListCheck) {
        setError(true);
        return;
      }
    }

    // SET FALSE TO CLOSE THE ACCORDIAN IF NO ERROR ON ITS SUBMIT after GETTING ERROR FROM PESAN SUBMIT
    setError(false);
    // SET PASSENGERS TYPE AND ID NUM
    let paxes = passengerList.map((item) => {
      let passenger = {
        idNumber: item.numberID,
        name: item.name,
        passengerType: item.passenger === "adult" ? "A" : "I",
        idNumType: item.passengerID === "KTP" ? 1 : 4,
      };
      if (item.passenger === "child") {
        passenger.dob = moment(item.childBirthDate).format("YYYY-MM-DD");
      }
      return passenger;
    });

    let request = {
      propScheduleId: ticketDetails.propscheduleid,
      tripId: ticketDetails.tripid,
      originId: ticketDetails.originid,
      destinationId: ticketDetails.destinationid,
      stasiunOrginCode: ticketDetails.stasiunorgcode,
      stasiunDestCode: ticketDetails.stasiundestcode,
      tripDate: ticketDetails.tripdate,
      departDate: ticketDetails.departdate,
      noka: ticketDetails.noka,
      wagonClassCode: ticketDetails.wagonclasscode,
      wagonClassId: ticketDetails.wagonclassid,
      customerName: name,
      phone: phoneNo,
      email: email,
      subClass: ticketDetails.subclass,
      ticketType: "R",
      totPsgAdult: stationDetails.adultPassengers,
      totPsgChild: 0,
      totPsgInfant: stationDetails.childPassengers,
      paxes: paxes,
    };
    setLoading(true);
    let response = await bookTicketApi(request);
    if (response.success) {
      const KAI = JSON.parse(localStorage.getItem("KAI"));
      KAI.bookingcode = response.data.bookingcode;
      localStorage.setItem("KAI", JSON.stringify(KAI));
      setLoading(false);
      navigate("/verefiedinputdata");
    } else {
      setLoading(false);
      showErrorPopUp({
        ...errorPopUp,
        showPopUp: true,
        message: response.message,
      });
    }
  };
  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div id={"InputData"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <Header header={header} back={"/stationpricecard"} step={"3"} />
      <div id={"InputDataForm"} style={{ padding: "16px" }}>
        <StationTicketCard stationTiciketCard={stationTiciketCard} />

        <ContactDetails submitError={error} />

        <PassengerInfo ticiketInfo={stationTiciketCard} submitError={error} />

        <PricingDetails stationTiciketCard={stationTiciketCard} />

        <Button
          id={"Pesan"}
          fullWidth
          children={"Pesan"}
          variant={"contained"}
          className={"submitBlackButton"}
          onClick={() => handlePesanSubmit()}
        />
      </div>
      <Footer />
    </div>
  );
};
export default InputData;

const PricingDetails = ({ stationTiciketCard }) => {
  const totalAmount = formatCurrency(stationTiciketCard.totalAmount || 0);
  return (
    <div style={{ margin: "24px 0 0 0" }}>
      <Typography children={"Rincian Harga"} className={"inputSectionLabels"} />
      <div style={{ marginTop: "16px" }}>
        <Accordion id={"RincianHarga-dropdown"} className={"dropShadow"}>
          <AccordionSummary style={{ borderBottom: "1px solid #DDD" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <KeyboardArrowDownIcon
                  style={{ marginRight: "12px" }}
                  className={"iconArrowInSelectInput"}
                />
                <Typography
                  id={"Harga-Yang-Anda-Bayar"}
                  children={"Harga yang Anda bayar"}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
              </div>
              <Typography
                id={"Harga-Yang-Anda-Bayar-value"}
                children={`${totalAmount}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  color: "#1A1A1A",
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="Argo-Dewasa"
                  children={`Argo (Dewasa) x ${stationTiciketCard.adultPassengers}`}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
                <Typography
                  id="Argo-Rp"
                  children={`${totalAmount}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="Argo-Bayi"
                  children={`Argo (Bayi) x ${stationTiciketCard.childPassengers}`}
                  style={{ fontWeight: "500", fontSize: "12px", color: "#666" }}
                />
                <Typography
                  id="GRATIS"
                  children={"GRATIS"}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#179D4F",
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
